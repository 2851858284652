import './index.less'
import { Card } from 'ant-design-vue'

export default {
  name: 'DashBoard',
  created() {
    // 实例创建完成后被立即调用
  },
  data() {
    return {
      content: '',
    }
  },
  mounted() {
    // 实例被挂载后调用
    this.$nextTick(() => {
      // 整个视图都渲染完毕后执行
    })
  },
  methods: {},

  render() {
    return (
      <Card class="dashboard-card">
        <div class="title">欢迎来到检测机构管理系统</div>
      </Card>
    )
  },
}
